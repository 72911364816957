import CardGrid from '@component/CardGrid';
import { EntryCard } from '@component/EntryIndex/EntryCard';
import Box from '@primitive/Box';
import Container from '@componentPrimitives/Container';
import Txt from '@primitive/Txt';
import { Entry } from '@lib/features/entries';
import { useView } from '@lib/store';
import { maybeGet } from '@liquorice/allsorts-craftcms-nextjs';
import { useTranslations } from 'next-intl';
import React from 'react';
import type { RelatedEntriesVariants } from './RelatedEntries.css';

export type RelatedEntriesProps = React.PropsWithChildren<
  RelatedEntriesVariants & {
    /**/
  }
>;

const RelatedEntries = (_props: RelatedEntriesProps) => {
  const t = useTranslations('common');
  const view = useView();

  const relatedEntries = (maybeGet(view, 'relatedEntries') ?? []) as Entry[];

  return relatedEntries?.length ? (
    <Box colorSet="primary" paper cx={{ pY: '5xl' }}>
      <Container>
        <Txt variant="h2" as="h2" autoMargin>
          {t('relatedContent')}
        </Txt>

        <CardGrid md={6} xl={3} items={relatedEntries} Component={EntryCard} />
      </Container>
    </Box>
  ) : (
    <></>
  );
};

export default RelatedEntries;
