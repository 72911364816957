import EventLayout from '@components/EventLayout';
import { getStaticPaths as utilGetStaticPaths } from '@lib/fetch/getStaticPaths';
import { createStaticPropsFetcher } from '@lib/fetch/getStaticProps';
import type { GetStaticPaths, GetStaticProps, NextPage } from 'next';

const ArticleView: NextPage = () => <EventLayout />;

export const getStaticProps: GetStaticProps = createStaticPropsFetcher('event');

export const getStaticPaths: GetStaticPaths = async () =>
  await utilGetStaticPaths({ entrySection: 'event' /* , limit: 20  */ }, true);

export default ArticleView;
