import Grid from '@componentPrimitives/Grid';
import RelatedEntries from '@component/RelatedEntries';
import Container from '@componentPrimitives/Container';
import { createLayout } from '@layout/Layout/createLayout';
import * as styles from './LayoutDefault.css';

const LayoutDefault = createLayout(
  ['header', 'beforeBody', 'afterBody', 'body', 'sidebar', 'footer'],
  (props, { slots }) => {
    const { header, beforeBody, afterBody, body, sidebar, footer } = slots ?? {};
    return (
      <>
        {header}
        <Container className={styles.container}>
          <Grid className={styles.grid}>
            {beforeBody && (
              <Grid.Col className={styles.beforeBodyCol} lg={8}>
                {beforeBody}
              </Grid.Col>
            )}
            <Grid.Col className={styles.contentCol} md={8} cx={{ mB: 'gutter' }}>
              {body}
            </Grid.Col>
            <Grid.Col className={styles.sidebarCol} as="aside" md={4} xl={3} mx={3} offsetXl={1}>
              {sidebar}
            </Grid.Col>
            {afterBody && (
              <Grid.Col className={styles.afterBodyCol} lg={8}>
                {afterBody}
              </Grid.Col>
            )}
          </Grid>
        </Container>

        {footer ?? <RelatedEntries key="relatedEntries" />}
      </>
    );
  }
);

export default LayoutDefault;
